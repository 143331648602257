#background {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-image: url("https://static-content-cdv-gis.storage.googleapis.com/background-images/background-image.jpg");
    background-color: #cccccc;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

#loading-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #FEFEFE;
    opacity: 0.9;
    align-items: center;
    box-shadow: 0 0 1em gray; 
    border-radius: 10px;
}

#loading-container > div > h1 {
    font-weight: 100;
    color: #2C2F30; 
    padding: 20px 0px;
}
