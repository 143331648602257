#background-home {
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-image: url("https://static-content-cdv-gis.storage.googleapis.com/background-images/background-image_flat.jpg");
    background-color: #cccccc;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-blend-mode: multiply;
    overflow-y: auto;
    overflow-x: hidden;
}

#content-home{
    min-height: 500px;
    padding-bottom: 20px;
}

p > a {
    text-decoration: none;
    color: #a3dee6;
}

p > a:hover{
    color: #015D90;
}
