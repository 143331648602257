.legenda-control{
	background-color:rgba(255,255,255,0.7);
	border: solid rgba(255, 255, 255, 1.0) 4px;
    box-shadow: 0 1px 5px rgba(0,0,0,0.65);
    border-radius: 3px;
}
.legendaAberta #divControlLegenda {
	width:auto;
	height:auto;
}
#wrapperLegenda{
	width:19px;
	height:19px;
}
.legendaAberta{
	width:auto !important;
	height:auto !important;
}
.linkLegenda{
	margin:2px;
	z-index: 99999;
	background-repeat:no-repeat;
	background-image:url('./toggle.png');
    transition: all .6s;
	position:absolute;
	top:-5px;
	right:-5px;
	width:17px;
	height:17px;
	transform: rotate(90deg);
}
.legendaAberta .linkLegenda{
	transform: rotate(270deg);
	background-color:white;
	border:solid 1px lightgray;
	border-radius:3px;
}
#divLegenda{
	display:none;
	transition: all .6s;
	max-height:400px;
	overflow-y:auto;
}
#divLegenda table td{
	padding:1px 2px;
}
#semLegendas{
	display:none;
	transition: all .6s;
	padding:5px 15px 5px 5px;
	overflow-y:auto;
}
.oculta{
	display:none !important;
}
.legendaAberta #semLegendas{
	display:block;
}
.legendaAberta #divLegenda{
	display:block;
	position:relative;
	background-color:rgba(255,255,255,0.7);
	padding:6px 5px 5px 5px;
}